import React, { Fragment, useEffect, useState } from "react";
import GptLayout from "./gptLayout";
import { useNavigate, useParams } from "react-router-dom";
import bookService from "../../services/bookService";
import { toast } from "react-toastify";
import Loader from "../reusable/Loader";
import "../../styles/chapterDetail.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaArrowLeft } from "react-icons/fa";
import ButtonLoader from "../reusable/ButtonLoader";
const ChapterDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [prevAnswer, setPrevAnswer] = useState("");
  const [answerData, setAnswerData] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await bookService.chapterDetailByChapterId(id);
      if (res) {
        setData(res?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const handleEditAnswer = (itm) => {
    setPrevAnswer(itm?.answer);
    setAnswerData(itm?.text);
    setEdit({ show: true, id: itm?._id });
  };

  const handleSaveChapterData = async (itm) => {
    setUpdateLoading(true);
    try {
      const res = await bookService.updateChapterData(
        itm,
        prevAnswer,
        answerData
      );
      if (res?.data?.success) {
        toast.success(res?.data?.message);
        setUpdateLoading(false);
        setPrevAnswer("");
        setAnswerData("");
        setEdit(false);
        fetchData();
      } else {
        toast.error(res?.data?.message);
        setUpdateLoading(false);
        setEdit(false);
      }
    } catch (error) {
      toast.error(error);
      setUpdateLoading(false);
    }
  };

  const modules = {
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: false,
    },
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        [{ font: [] }],
        [{ size: ["12px", "14px", "16px", "18px", "20px"] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ["link"],
      ],
      // handlers: {
      //   undo: () => {
      //     alert("Undo action triggered");
      //   },
      //   redo: () => {
      //     alert("Redo action triggered");
      //   },
      // },
    },
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <GptLayout>
          <section className="chapter_details_list">
            <div className="title">
              <button onClick={() => navigate(-1)}>
                <FaArrowLeft />
              </button>
              <h2>Chapter Collection List</h2>
            </div>

            {data?.length > 0 ? (
              <div className="chapter_details_wrapper">
                {data?.map((ele) => (
                  <div className="chapter_data" key={ele?._id}>
                    <div className="chapter_head">
                      <h3>Question : {ele?.question}</h3>
                    </div>
                    <div className="chapter_answer">
                      {ele?.answer && (
                        <p>
                          Answer :<span> {ele?.answer}</span>
                          <span
                            dangerouslySetInnerHTML={{ __html: ele?.text }}
                          />
                        </p>
                      )}

                      <button
                        className="edit_btn"
                        onClick={() => handleEditAnswer(ele)}
                      >
                        {edit?.show && ele?._id === edit?.id
                          ? "Cancel"
                          : `Edit`}
                      </button>
                    </div>
                    {edit?.show && ele?._id === edit?.id && (
                      <div className="edit_form">
                        {/* <label>Answer :</label>
                        <textarea
                          value={prevAnswer}
                          rows={5}
                          onChange={(e) => setPrevAnswer(e.target.value)}
                          placeholder="Write your answer"
                        /> */}
                        <label>Add additional data:</label>
                        <ReactQuill
                          modules={modules}
                          value={answerData}
                          onChange={setAnswerData}
                        />
                        <button onClick={() => handleSaveChapterData(ele)}>
                          {updateLoading ? <ButtonLoader /> : "Save"}
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="no_data">
                <p>You havn't added any chapter to the list</p>
              </div>
            )}
          </section>
        </GptLayout>
      )}
    </Fragment>
  );
};

export default ChapterDetail;
