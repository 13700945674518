import React, { Fragment } from "react";
import "../../styles/whatCanDo.scss";
import {
  assessOlq,
  getExpertGuidance,
  keepNotesOrganized,
  unlimitedMockInterviews,
  unlimitedOirpractice,
  videoNotesfeature,
} from "../../assets/images";

const WhatCanDo = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="why_choose_us_ssb_gpt">
          <h1 className="title">What Can You Do with SSB-GPT?</h1>
          <div className="card_list">
            <div className="card">
              <img src={assessOlq} alt="explore campus" />
              <h2>Your One-Stop Resource for CDS</h2>
              <p>
                Everything you need for CDS is right here—no need to look
                anywhere else.
              </p>
            </div>
            <div className="card">
              <img src={getExpertGuidance} alt="connect improve" />
              <h2>Customizable Learning Experience</h2>
              <p>
                Choose the content format that suits you best—be it text or
                video—for a tailored learning experience.
              </p>
            </div>
            <div className="card">
              <img src={keepNotesOrganized} alt="connect practice" />
              <h2>Simplify Your CDS Study Routine</h2>
              <p>
                Organize all your CDS study materials and notes in one
                convenient place.
              </p>
            </div>
            <div className="card">
              <img src={videoNotesfeature} alt="connect practice" />
              <h2>Streamlined Learning</h2>
              <p>
                Access notes alongside videos, eliminating the need to rewatch
                content repeatedly.
              </p>
            </div>

            <div className="card">
              <img src={unlimitedMockInterviews} alt="connect practice" />
              <h2>Unlimited CDS Mock Tests</h2>
              <p>
                Choose to practice CDS mock tests by individual subjects or
                cover the entire syllabus—whatever fits your preparation style.
              </p>
            </div>
           
            <div className="card">
              <img src={unlimitedOirpractice} alt="connect practice" />
              <h2>CDS-GPT: Your Personalized CDS teacher</h2>
              <p>
                Get answers to your SSB interview queries instantly from our
                AI-powered platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WhatCanDo;
