let domain = process.env.REACT_APP_API;
let localDomain = process.env.REACT_APP_TEST_URL;
let dbUrl = process.env.REACT_APP_API + "/ssb-gpt/";
// let localDomain = "http://127.0.0.1:5000/";

const userEndPoints = {
  login: domain + "/login",
  userprofile: domain + "/profile",
  chat: localDomain + "chat",
  askQuestion: localDomain + "ask_question",
  pdfUpload: localDomain + "upload",
  linkSummarize: localDomain + "summarize",
  generateImage: dbUrl + "get-image",
  generate_mcq: localDomain + "generate_mcqs",
  askInterviewQuestion: localDomain + "ask",
  giveAnswer: localDomain + "answer",

  getSrt: localDomain + "get_situation",
  submitSrt: localDomain + "submit_response",

  searchVideo: domain + "/search-video/",

  // BOOK API'S
  createNewBook: domain + "/create-book",
  createChapter: domain + "/create-chapter-cover",
  deleteChapter: domain + "/delete-chapter-cover",
  myBookList: domain + "/my-books",
  getChapterByBookId: domain + "/get-chapter-covers",

  chapterDetail: domain + "/get-chapter-data",
  createChapterData: domain + "/create-chapter/",
  updateChapterData: domain + "/update-chapter-data/",
};

export { userEndPoints };
