import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import "../../styles/gptBooks.scss";
import { imgAdd, ssbBook } from "../../assets/images";
import { useAuth } from "../../context/userContext";
import bookService from "../../services/bookService";
import PopupPrompt from "../reusable/PopupPrompt";
import { toast } from "react-toastify";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
// import { FaPlus } from "react-icons/fa";
const GptBooks = ({
  bookList,
  fetchBookList,
  handleSave,
  outputData,
  // activeTab,
  handleShowHistory,
  questionList,
}) => {
  const [auth] = useAuth();
  const divRef = useRef(null);
  const [addBook, setAddBook] = useState(false);
  const [bookName, setBookName] = useState("");
  const [newBookLoading, setNewBookLoading] = useState(false);
  
  const [addChapter, setAddChapter] = useState(false);
  const [chapterBook, setChapterBook] = useState("");
  const [chapterId, setChapterId] = useState("");
  const [chapterName, setChapterName] = useState("");
  const [saveIndex, setSaveIndex] = useState(0);
  const [chapterModal, setChapterModal] = useState(false);
  const [bookChapterList, setBookChapterList] = useState();
  const [showHistory, setShowHistory] = useState(false);
  const [showBook, setShowBook] = useState(false);

  const [chapterLoading, setChapterLoading] = useState(false);
  const [viewHistory, setViewHistory] = useState(false);


  

  

  // BOOK STATE HANDLING STARTS
  const handleAddChapter = () => {
    setAddChapter(true);
    setChapterModal(false);
  };

  const [saveUnit, setSaveUnit] = useState(false);

  const addNewUnit = async () => {
    setNewBookLoading(true);
    const data = outputData?.find((_, id) => saveIndex === id);
    const type = "text";
    try {
      const res = await bookService.addNewChapterData(chapterId, type, data);
      if (res?.data?.success) {
        setSaveUnit(false);
        setChapterBook("");
        setChapterId("");
        setNewBookLoading(false);
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
        setSaveUnit(false);
        setChapterBook("");
        setChapterId("");
        setNewBookLoading(false);
      }
    } catch (error) {
      setNewBookLoading(false);
      setSaveUnit(false);
    }
  };

  // ADD NEW BOOK ENDS

  const optionBookList = useMemo(() => {
    return bookList?.map((ele) => ({
      option: ele?.name,
      value: ele?._id,
    }));
  }, [bookList]);

  const chapterListFromBookId = useMemo(() => {
    return bookChapterList?.chapters?.map((ele) => ({
      option: ele?.name,
      value: ele?._id,
    }));
  }, [bookChapterList]);

  // ADD NEW CHAPTER STARTS

  useEffect(() => {
    if (handleSave) {
      setSaveIndex(handleSave);
      setSaveUnit(true);
    }
  }, [handleSave]);

  const handleAddNewChapter = async () => {
    setNewBookLoading(true);
    try {
      const res = await bookService.addNewChapter(chapterBook, chapterName);
      if (res?.data?.success) {
        setAddChapter(false);
        setChapterBook("");
        setNewBookLoading(false);
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
        setAddChapter(false);
        setChapterBook("");
        setNewBookLoading(false);
      }
    } catch (error) {
      setNewBookLoading(false);
      setAddChapter(false);
    }
  };

  const openBook = (id) => {
    setChapterModal(true);
    getChapterListBybookId(id);
  };

  const getChapterListBybookId = async (id) => {
    setChapterLoading({
      loading: true,
      index: id,
    });
    try {
      const res = await bookService.myAllChapterbyBookid(id);
      if (res?.data?.success) {
        setShowBook(false);
        setBookChapterList(res?.data);
        setChapterLoading(false);
      }
    } catch (error) {
      setShowBook(false);
      setChapterLoading(false);
    }
  };

  // GET CHAPTER LIST BY BOOK ID

  useEffect(() => {
    if (chapterBook !== "") {
      getChapterListBybookId(chapterBook);
    }
  }, [chapterBook]);

 

  const handleAddnewBook = async () => {
    setNewBookLoading(true);
    try {
      const res = await bookService.createUserNewBook(bookName);
      if (res?.data) {
        toast.success("Book Add Successfully");
        setNewBookLoading(false);
        fetchBookList();
        setBookName("");
        setAddBook(false);
      }
    } catch (error) {
      setNewBookLoading(false);
      setBookName("");
      setAddBook(false);
    }
  };
  
  const handleAddBookModal = () => {
    if (auth?.user?._id) {
      setAddBook(true);
    } else {
      toast.error("Login before adding new book");
    }

    setShowBook(false);
  };

  return (
    <Fragment>
      <div className="gpt_books_wrapper">
        <h2>Books</h2>
        <ul>
          {bookList?.map((ele) => (
            <li key={ele?._id}>
              <div className="book_tab" onClick={() => openBook(ele?._id)}>
                <img src={ssbBook} alt="ssb_book" />
                <p>{ele?.name}</p>
              </div>
            </li>
          ))}
          <li>
            <div className="book_tab" onClick={() => handleAddBookModal(true)}>
              <img src={imgAdd} alt="imgAdd" />
              <p>Add New</p>
            </div>
          </li>
        </ul>
      </div>

      <section className="mobile_bar_content">
        <div className="container">
          <div className="mobile_navigation">
            <span onClick={() => setShowHistory(true)}>History</span>
            <span onClick={() => setShowBook(true)}>Book</span>
          </div>
        </div>

        <div className={`book_list ${showBook ? "book_list_show" : ""}`}>
          <div className="gpt_books">
            <div className="title">
              <h2>Books</h2>
              <span onClick={() => setShowBook(false)}>
                <RxCross2 />
              </span>
            </div>
            <ul>
              {bookList?.map((ele) => (
                <li key={ele?._id}>
                  <div
                    className="book_tab"
                    onClick={() => getChapterListBybookId(ele?._id)}
                  >
                    <img src={ssbBook} alt="ssb_book" />
                    <p>
                      {chapterLoading?.loading &&
                      chapterLoading?.index === ele?._id
                        ? "Loading.."
                        : ele?.name}
                    </p>
                  </div>
                </li>
              ))}
              <li>
                <div className="book_tab" onClick={() => handleAddBookModal()}>
                  <img src={imgAdd} alt="imgAdd" />
                  <p>Add New</p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div
          className={`history_list ${showHistory ? "history_list_show" : ""}`}
        >
          <div className="gpt_books">
            <div className="title">
              <h2>Search History</h2>
              <span onClick={() => setShowHistory(false)}>
                <RxCross2 />
              </span>
            </div>
            <ul>
              {questionList?.length > 0 ? (
                questionList?.map((ele, id) => (
                  <li key={id} onClick={() => setViewHistory(!viewHistory)}>
                    <p>
                      {ele?.dayData}
                      {!viewHistory ? (
                        <RiArrowDropDownLine />
                      ) : (
                        <RiArrowDropUpLine />
                      )}
                    </p>

                    {viewHistory && (
                      <span onClick={() => handleShowHistory(ele?.dayData)}>
                        {ele?.searchData?.map((item, id) => (
                          <p key={id}>
                            {id + 1}. {item?.question}
                          </p>
                        ))}
                      </span>
                    )}
                  </li>
                ))
              ) : (
                <li>
                  <p>No search history found</p>
                </li>
              )}
            </ul>
          </div>
        </div>
      </section>

      {addBook && (
        <PopupPrompt
          divRef={divRef}
          cancelBtnTxt="Cancel"
          cancelOnclick={() => setAddBook(false)}
          confirmBtnTxt="Save Book"
          confirmOnclick={() => handleAddnewBook()}
          title="Add Your New Book"
          newBookPlaceholder="Write your book name"
          newBookLabel="Book Name"
          newbookValue={bookName}
          loading={newBookLoading}
          onNewBookChange={(e) => setBookName(e.target.value)}
        />
      )}

      {addChapter && (
        <PopupPrompt
          divRef={divRef}
          cancelBtnTxt="Cancel"
          cancelOnclick={() => setAddChapter(false)}
          confirmBtnTxt="Save Chapter"
          confirmbtnWidth="140px"
          confirmOnclick={() => handleAddNewChapter()}
          title="Save Chapter"
          newChapterPlaceholder="Select your book name"
          newChapterLabel="Book Name"
          newChapterValue={chapterBook}
          loading={newBookLoading}
          bookOption={optionBookList}
          onNewChapterChange={(e) => setChapterBook(e.target.value)}
          onNewChapterNameChange={(e) => setChapterName(e.target.value)}
          newChapterNamePlaceholder="Enter your chapter name"
          newChapterNameLabel="Chapter Name"
          newChapterNameValue={chapterName}
        />
      )}

      {saveUnit && (
        <PopupPrompt
          divRef={divRef}
          cancelBtnTxt="Cancel"
          cancelOnclick={() => setSaveUnit(false)}
          confirmBtnTxt="Save Topic"
          confirmbtnWidth="140px"
          confirmOnclick={() => addNewUnit()}
          title="Add Chapter"
          newChapterPlaceholder="Select your book name"
          newChapterLabel="Book Name"
          newChapterValue={chapterBook}
          loading={newBookLoading}
          bookOption={optionBookList}
          onNewChapterChange={(e) => setChapterBook(e.target.value)}
          onChapterIdChange={(e) => setChapterId(e.target.value)}
          chapterIdPlaceholder="Select your chapter name"
          chapterIdLabel="Chapter Name"
          chapterIdValue={chapterId}
          chapterOption={chapterListFromBookId}
        />
      )}

      {chapterModal && (
        <PopupPrompt
          ref={divRef}
          confirmBtnTxt="Add Chapter"
          confirmbtnWidth="160px"
          confirmOnclick={() => handleAddChapter()}
          cancelBtnTxt="Close List"
          cancelOnclick={() => setChapterModal(false)}
          title={"Chapter List"}
          chapterData={bookChapterList?.chapters}
        />
      )}
    </Fragment>
  );
};

export default GptBooks;
