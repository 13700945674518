import React, { Fragment, useEffect, useRef, useState } from "react";
import GptLayout from "./gptLayout";
import "../../styles/gptSearch.scss";
import { CiSearch } from "react-icons/ci";
import { AiFillAudio, AiFillDislike, AiFillLike } from "react-icons/ai";

import { LuBookmark, LuLoader } from "react-icons/lu";
import { RiVoiceprintFill } from "react-icons/ri";
import GptSidebar from "./gptSidebar";
import { TiArrowUp } from "react-icons/ti";
import { MdAdd, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { useTour } from "@reactour/tour";
import {
  askSsbIcon,
  courses,
  currentAffair,
  englishIcon,
  gkIcon,
  hideVideo,
  lamp1,
  lamp2,
  lamp3,
  linkSearch,
  logo_mobile,
  mathIcon,
  searchBook,
} from "../../assets/images";
import bookService from "../../services/bookService";
import { BsPlayCircle } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Loader from "../reusable/Loader";
import { useAuth } from "../../context/userContext";

import CustomHelmet from "../reusable/Helmet";
import WhatCanDo from "./WhatCanDo";
import GptBooks from "./gptBooks";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

const GptSearch = () => {
  const [auth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const localToken = localStorage.getItem("ssb-token")
      ? JSON.parse(localStorage.getItem("ssb-token"))
      : {};

    const data = {
      success: true,
      token: token,
    };
    if (!localToken) {
      localStorage.setItem("ssb-token", data);
    }
  }, [location.search]);

  const [searchValue, setSearchValue] = useState("");
  const [placeHolderInput] = useState("Search anything related to ssb");
  const [activeTab, setActiveTab] = useState("question");
  const [activeinput, setActiveInput] = useState(false);
  const [outputData, setOutputData] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [isListening, setIsListening] = useState(false);
  const [isLoading] = useState(false);
  // const [localHistory, setlocalHistory] = useState([]);
  const [mediaOpen, setMediaOpen] = useState(false);
  // const [pdfFile, setPdfFile] = useState("");
  const [srchToday, setSrchToday] = useState(true);

  // ALL BOOK SAVE STATE STARTS

  // useEffect(() => {
  //   const existingLocalHistory = localStorage.getItem("userHistory")
  //     ? JSON.parse(localStorage.getItem("userHistory"))
  //     : [];
  //   setlocalHistory(existingLocalHistory);
  // }, []);

  // const todatDate = new Date();
  // const currentDate = moment(todatDate).format("YYYY-MM-DD");

  const handleVoice = () => {
    setIsListening(true);
    const recognition = new SpeechRecognition();
    recognition.lang = "en-US";
    recognition.maxResults = 10;
    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setSearchValue(transcript);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognition.start();
  };

  const [disableVideo, setDisableVideo] = useState(
    () => JSON.parse(localStorage.getItem("videoSrch")) ?? true
  );

  const handleDisableVideo = () => {
    setDisableVideo((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("videoSrch", JSON.stringify(disableVideo));
  }, [disableVideo]);

  //  POST DATA TO API ENDS

  const handleMediaOpen = () => {
    setMediaOpen(!mediaOpen);
  };

  //Link TEST GENRATE ENDS

  // HANDLE PDF UPLOAD START
  const handlePdfSearch = () => {
    setActiveInput(true);
    setActiveTab("pdf");
    setMediaOpen(false);
  };

  // const handleMediaChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setPdfFile(file);
  //   }
  // };

  // const handlePdfUpload = async () => {
  //   setIsLoading(true);
  //   try {
  //     const formData = new FormData();
  //     formData.append("file", pdfFile);

  //     const result = await axios.post(userEndPoints.pdfUpload, formData);

  //     if (result) {
  //       setOutputData([
  //         ...outputData,
  //         { answer: result?.data?.response, question: searchValue },
  //       ]);
  //       setIsLoading(false);
  //       setSearchValue("");
  //       setPlaceholderInput("Search anything from provided pdf");
  //       setActiveTab("pdf_srch");
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     setMediaOpen(false);
  //   }
  // };

  // const handlePdfSearchQuestion = async () => {
  //   setIsLoading(true);
  //   setSearchValue("");
  //   setMediaOpen(false);
  //   setSrchToday(true);
  //   const newEntry = { question: searchValue, answer: "Loading..." };
  //   setOutputData((prevData) => [...prevData, newEntry]);
  //   try {
  //     const result = await axios.post(userEndPoints.askQuestion, {
  //       input_text: searchValue,
  //     });

  //     if (result) {
  //       setOutputData((prevData) =>
  //         prevData.map((entry, index) =>
  //           index === prevData.length - 1
  //             ? {
  //                 ...entry,
  //                 answer: result?.data?.response.replace(/Alpha Bot:\s*/g, ""),
  //               }
  //             : entry
  //         )
  //       );
  //       const currentDate = moment().format("YYYY-MM-DD");
  //       let updatedList = [...questionList];

  //       if (
  //         updatedList.length > 0 &&
  //         updatedList[updatedList.length - 1].dayData === currentDate
  //       ) {
  //         updatedList[updatedList.length - 1].searchData.push({
  //           question: searchValue,
  //           answer: result?.data?.response,
  //         });
  //       } else {
  //         updatedList.push({
  //           dayData: currentDate,
  //           searchData: [
  //             {
  //               question: searchValue,
  //               answer: result?.data?.response,
  //             },
  //           ],
  //         });
  //       }
  //       setQuestionList(updatedList);
  //       setlocalHistory(updatedList);
  //       localStorage.setItem("userHistory", JSON.stringify(updatedList));
  //       setIsLoading(false);
  //       setPlaceholderInput("Search anything from provided pdf");
  //       setActiveTab("pdf_srch");
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     setMediaOpen(false);
  //   }
  // };

  // HANDLE PDF UPLOAD ENDS

  const handleVideoView = (url) => {
    // const videoId = getYouTubeVideoId(url);
    if (url) {
      navigate(`/video?streamId=${url}`);
    }
  };

  // HANDLE VIDEO SEARCH ENDS

  const handleNavigateCourses = () => {
    window.open("https://testing.alpharegiment.in/ssb-courses");
  };

  // INTERVIEW ATTEND FUNCTION API'S CALL ENDS
  // INTERVIEW ATTEND FUNCTION API'S CALL ENDS

  // test start timer

  // STEPS FOR GIVING OVERVIEW
  const { currentStep, isOpen } = useTour();

  useEffect(() => {
    if (currentStep === 0 && isOpen) {
      setMediaOpen(true);
    }
  }, [currentStep, isOpen]);

  // SCROLL HER PAGE AFTER EVERY SEARCH STARTS
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current && outputData) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [outputData]);

  // SCROLL HER PAGE AFTER EVERY SEARCH ENDS

  // CHECK USER TODAY SEARCH HISTORY STARTS
  const savedList = localStorage.getItem("userHistory");

  useEffect(() => {
    if (savedList) {
      const data = JSON.parse(savedList);
      if (data === null) {
        setSrchToday(true);
      } else {
        const today = new Date().toISOString().split("T")[0];
        const checkSearchToday = data.some((item) => item.dayData === today);
        const searchHistory = data.find(
          (item) => item.dayData === today
        )?.searchData;
        if (searchHistory) {
          setOutputData(searchHistory);
        }
        setSrchToday(checkSearchToday);
      }
      setQuestionList(data);
    }
  }, [savedList]);

  const handleShowHistory = (date) => {
    const extractedData = questionList
      ?.filter((ele) => ele?.dayData === date)
      .map((item) => item.searchData)
      .flat();
    setOutputData(extractedData);
  };

  // CHECK USER TODAY SEARCH HISTORY ENDS

  // SPOKEN ENGLISH STARTS

  // const handleSpokenEnglish = () => {
  //   setActiveTab("spokenEnglish");
  //   setMediaOpen(false);
  //   setSrchToday(true);
  //   setOutputData((prevData) => [
  //     ...prevData,
  //     {
  //       question:
  //         "Everyone learns differently, so here you can improve your spoken english in various ways. Choose the method that works best for you.",
  //       spokenType: [
  //         {
  //           type: "Watch Videos for Spoken English Basics",
  //           query: "",
  //         },
  //         {
  //           type: "Learn Spoken English with AI",
  //           query: "/spoken-english",
  //         },
  //         {
  //           type: "Group Discussion with Other Aspirants",
  //           query: "/spoken-english",
  //         },
  //       ],
  //     },
  //   ]);
  //   let updatedList = [...localHistory];
  //   const existingEntry = updatedList.find(
  //     (item) => item.dayData === currentDate
  //   );
  //   if (existingEntry) {
  //     existingEntry.searchData.push({
  //       question:
  //         "Everyone learns differently, so here you can improve your spoken english in various ways. Choose the method that works best for you.",

  //       spokenType: [
  //         {
  //           type: "Watch Videos for Spoken English Basics",
  //           query: "",
  //         },
  //         {
  //           type: "Learn Spoken English with AI",
  //           query: "/spoken-english",
  //         },
  //         {
  //           type: "Group Discussion with Other Aspirants",
  //           query: "/spoken-english",
  //         },
  //       ],
  //     });
  //   } else {
  //     updatedList.push({
  //       dayData: currentDate,
  //       searchData: [
  //         {
  //           question:
  //             "Everyone learns differently, so here you can improve your spoken english in various ways. Choose the method that works best for you.",

  //           spokenType: [
  //             {
  //               type: "Watch Videos for Spoken English Basics",
  //               query: "",
  //             },
  //             {
  //               type: "Learn Spoken English with AI",
  //               query: "/spoken-english",
  //             },
  //             {
  //               type: "Group Discussion with Other Aspirants",
  //               query: "/spoken-english",
  //             },
  //           ],
  //         },
  //       ],
  //     });
  //   }
  //   setQuestionList(updatedList);
  //   setlocalHistory(updatedList);
  //   localStorage.setItem("userHistory", JSON.stringify(updatedList));
  // };

  // HANDLE SPOKEN QUERY
  const handleSpokenQuery = (query) => {
    navigate(query);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && searchValue !== "") {
      handleSearch();
    }
  };

  // HANDLE FUNCTION FOR ANALYSING USER INPUT
  // const keywords = {
  //   srt: ["srt", "situation", "situations", "Give SRT", "Practice srt"],
  //   link: ["http", "https", "www"],
  //   ppdt: ["PP&DT", "PP & DT", "ppdt", "PPDT"],
  //   tat: ["tat", "TAT"],
  //   wat: ["word", "wat", "WAT"],
  //   interview: ["interview"],
  //   pdfSearch: ["pdf", "pdfSearch"],
  //   olq: ["olq", "OLQ", "olq analysis"],
  //   oir: ["Give OIR", "OIr test", "oir"],
  //   spoken: ["Spoken English", "Spoken", "english"],
  //   video: ["video", "learn by video"],
  //   courses: [
  //     "Coaching",
  //     "SSB Institute",
  //     "Psychology dossier checked by ex-psychologist ",
  //     "Mock Psychology",
  //     "Mock Interview",
  //     "Mock SSB",
  //   ],
  // };

  const [message, setMessage] = useState("");
  // const [suggestionTab, setSuggestionTab] = useState("ask");

  const handleInputChange = (e) => {
    const valueData = e.target.value;
    setSearchValue(valueData);
    // analyzeInput(valueData.trim());
  };

  //   const analyzeInput = (input) => {
  //     const lowerCaseInput = String(input).toLowerCase();
  //     let matchedKeyword = null;
  //     Object.keys(keywords).forEach((keyword) => {
  //       if (
  //         keywords[keyword].some((pattern) =>
  //           lowerCaseInput.includes(pattern.toLowerCase())
  //         )
  //       ) {
  //         matchedKeyword = keyword;
  //       }
  //     });
  //     if (matchedKeyword === "srt") {
  //       setMessage(`Would you like to get SRT?`);
  //       setSuggestionTab("srt");
  //     }
  //     if (matchedKeyword === "wat") {
  //       setMessage(`Would you like to get WAT?`);
  //     }
  //     if (matchedKeyword === "ppdt") {
  //       setMessage(`Would you like to get  PPDT / PP&DT?`);
  //       setSuggestionTab("PPDT");
  //     }
  //     if (matchedKeyword === "tat") {
  //       setMessage(`Would you like to get TAT ?`);
  //       setSuggestionTab("TAT");
  //     }
  //     if (matchedKeyword === "oir") {
  //       setMessage(`Would you like to get OIR ?`);
  //       setSuggestionTab("oir");
  //     }
  //     if (matchedKeyword === "pdfSearch") {
  //       setMessage(`Would you like a summary, MCQs, or specific information from the PDF?
  // `);
  //       setSuggestionTab("pdf");
  //     }
  //     if (matchedKeyword === "olq") {
  //       setMessage(
  //         `Do you mean you want to get your OLQ analysis through a simple psychometric test?`
  //       );
  //     }
  //     if (matchedKeyword === "link") {
  //       setMessage(
  //         `Would you like a summary, MCQs, or specific information from this link?`
  //       );
  //       setSuggestionTab("link");
  //     }

  //     if (matchedKeyword === "interview") {
  //       setMessage(`Want to give an interview`);
  //       setSuggestionTab("interview");
  //     }
  //     if (matchedKeyword === "courses") {
  //       setMessage(`Want to browse ssb courses`);
  //       setSuggestionTab("courses");
  //     }

  //     if (matchedKeyword === "video") {
  //       setMessage(`Would you like your questions answered in a video?
  // `);
  //       setSuggestionTab("video");
  //     }

  //     if (matchedKeyword === "spoken") {
  //       setMessage(`Do you mean you want to practice spoken English with AI?
  // `);
  //       setSuggestionTab("spokenEnglish");
  //     }

  //     return matchedKeyword;
  //   };

  // HANDLE SEARCH VIW POPUP MESSAGE

  const handleSearchPop = () => {
    setMessage("");
  };

  const handleSearch = () => {
    setMessage("");
  };

  // SPOKEN ENGLISH ENDS

  const [authLoading, setAuthLoading] = useState(false);
  const [bookList, setBookList] = useState([]);
  const fetchBookList = async () => {
    try {
      setAuthLoading(true);
      const res = await bookService.myAllBookList();
      if (res) {
        setAuthLoading(false);
        setBookList(res?.data?.books);
      }
    } catch (error) {
      setAuthLoading(false);
    }
  };

  useEffect(() => {
    if (auth?.user?._id) {
      fetchBookList();
    }
  }, [auth?.user?._id]);
  const [saveValue, setSaveValue] = useState();

  const handleSave = (idx) => {
    setSaveValue(idx);
  };

  return authLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <CustomHelmet
        title="CDS-GPT: Your CDS Interview Self Preparation Tool"
        description="Prepare effectively for your CDS/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
        <section className="gpt_layout_wrapper">
          <GptSidebar setOutputData={setOutputData} />
          <section className="gpt_Search_box">
            <div className="input_box">
              <div className={`srch_output custonScroll`} ref={scrollRef}>
                {!srchToday && (
                  <div className="wlcm_msg">
                    <div className="title">
                      <h2>CDS-GPT</h2>
                    </div>
                    <div className="overview_box">
                      <div className="over_view_msg">
                        <div className="sub_title">
                          <img src={lamp1} alt="lamp1" />
                          <p>Examples</p>
                        </div>
                        <ul>
                          <li>
                            <p>Provide me with study material on Geography.</p>
                          </li>
                          <li>
                            <p>
                              Give me with a short test on this Geography topic.
                            </p>
                          </li>
                          <li>
                            <p>Today's current affairs.</p>
                          </li>
                        </ul>
                      </div>
                      <div className="over_view_msg">
                        <div className="sub_title">
                          <img src={lamp2} alt="lamp2" />
                          <p>Capabilities</p>
                        </div>
                        <ul>
                          <li>
                            <p>
                              CDS-GPT offers study material for all CDS
                              subjects.
                            </p>
                          </li>
                          <li>
                            <p>CDS-GPT can conduct unlimited mock tests.</p>
                          </li>
                          <li>
                            <p>
                              CDS-GPT provides content in both text and video
                              formats.
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="over_view_msg">
                        <div className="sub_title">
                          <img src={lamp3} alt="lamp3" />
                          <p>Quality</p>
                        </div>
                        <ul>
                          <li>
                            <p>Trained by CDS Teachers.</p>
                          </li>
                          <li>
                            <p>Keep all your notes organized in one place.</p>
                          </li>
                          <li>
                            <p>
                              CDS-GPT tracks your progress & suggests optimal
                              strategies.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                {/* SEARCH HISORTY AND ASK QUESTION BOX  */}
                {outputData?.length > 0 &&
                  outputData?.map((ele, idx) => (
                    <>
                      {ele?.question && <h5>{ele?.question}</h5>}
                      {ele?.srtList?.length > 0 && (
                        <h5>
                          {ele?.srtList?.map((itm, id) => (
                            <span key={id}>
                              {id + 1}. {itm}
                            </span>
                          ))}
                        </h5>
                      )}
                      {(ele?.answer || ele?.spokenType?.length > 0) && (
                        <div className="answer_box">
                          <button
                            className="stop_video"
                            title="Disable Video suggestion"
                            onClick={() => handleDisableVideo()}
                          >
                            <img src={hideVideo} alt="hideVideo" />
                          </button>
                          <div>
                            <div className="output_box">
                              {ele?.spokenType?.length > 0 &&
                                ele?.spokenType?.map((itm, id) => (
                                  <button
                                    key={id}
                                    onClick={() =>
                                      handleSpokenQuery(itm?.query)
                                    }
                                  >
                                    {itm?.type}
                                  </button>
                                ))}

                              <div className="video_data">
                                <div className="video_content" key={idx}>
                                  {ele?.title !== "" && <p>{ele?.title}</p>}
                                  {ele?.url && ele?.url !== null && (
                                    <div className="thumbnail-container">
                                      <iframe
                                        className="thumbnail"
                                        src={ele?.url}
                                        title={ele?.url}
                                        frameBorder="0"
                                        allowFullScreen
                                      />
                                      <div
                                        className="overlay"
                                        onClick={() =>
                                          handleVideoView(ele?.url)
                                        }
                                      >
                                        <BsPlayCircle />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>

                              {ele?.answer !== "" ? (
                                <>
                                  {ele?.answer?.Title && (
                                    <p>
                                      <strong>Title :</strong>
                                      <span>{ele?.answer?.Title}</span>
                                    </p>
                                  )}
                                  {ele?.answer?.Content && (
                                    <p>
                                      <strong>Content :</strong>
                                      <span>{ele?.answer?.Content}</span>
                                    </p>
                                  )}
                                  {ele?.answer?.Summary && (
                                    <p>
                                      <strong>Summary :</strong>
                                      <span>{ele?.answer?.Summary}</span>
                                    </p>
                                  )}
                                  {!ele?.answer?.Title &&
                                    !ele?.answer?.Content &&
                                    !ele?.answer?.Summary && (
                                      <p>
                                        <span>
                                          {ele?.answer?.replace(
                                            /Alpha Bot:\s*/g,
                                            ""
                                          )}
                                        </span>
                                      </p>
                                    )}
                                </>
                              ) : (
                                <div className="video_data">
                                  <div className="video_content" key={idx}>
                                    {ele?.title !== "" && <p>{ele?.title}</p>}
                                    {ele?.url === "Loading..." ? (
                                      <p>{ele?.url}</p>
                                    ) : (
                                      <div
                                        className="thumbnail"
                                        onClick={() =>
                                          handleVideoView(ele?.url)
                                        }
                                      >
                                        <img
                                          src={logo_mobile}
                                          alt="thumbnail"
                                        />
                                        <span>
                                          <BsPlayCircle />
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="action_btn">
                              <span onClick={() => handleSave(idx)}>
                                <LuBookmark />
                                Save
                              </span>
                              <span>
                                <AiFillDislike />
                              </span>
                              <span>
                                <AiFillLike />
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}

                {/* SEARCH HISTORY AND ASK QUESTION BOX ENDS */}

                <div className="tour_msg"></div>
                {/* HANLDE TAT LOGICS STARTS  */}

                {activeTab === "TAT" && (
                  <div className="tat_test_msg">
                    <p>
                      Your test is preparing. It will take upto 5-6 min. <br />
                      Do not refresh the page in case you may loss data
                    </p>
                  </div>
                )}

                {activeTab === "PPDT" && (
                  <div className="tat_test_msg">
                    <p>
                      Your test is preparing. It will take upto 40 - 50 sec.{" "}
                      <br />
                      Do not refresh the page in case you may loss data
                    </p>
                  </div>
                )}
              </div>

              <div className="srch_input">
                {message !== "" && (
                  <div className="suggestion_box">
                    <p>{message}</p>
                    <div className="box">
                      <button onClick={() => setMessage("")}>No</button>
                      <button onClick={handleSearchPop}>Yes</button>
                    </div>
                  </div>
                )}
                <div>
                  {activeTab === "pdf" && (
                    <div
                      className="upload_box"
                      style={{ border: activeinput ? "1px solid #fff" : "" }}
                    >
                      <input
                        type="file"
                        accept="*/.pdf"
                        onFocus={() => {
                          setMediaOpen(false);
                        }}
                        // onChange={(e) => handleMediaChange(e)}
                        placeholder={placeHolderInput}
                      />
                      <button
                        className="media_btn"
                        onClick={() => handleSearch()}
                      >
                        {isLoading ? <LuLoader /> : <TiArrowUp />}
                      </button>
                    </div>
                  )}

                  {activeTab !== "pdf" && (
                    // && activeTab !== "srt"
                    <input
                      type="text"
                      value={searchValue}
                      style={{ border: activeinput ? "1px solid #fff" : "" }}
                      onChange={(e) => handleInputChange(e)}
                      onFocus={() => {
                        setMediaOpen(false);
                      }}
                      onKeyDown={handleKeyDown}
                      placeholder={placeHolderInput}
                    />
                  )}

                  <div className="media_wrapper">
                    <div
                      className={`media_drop_up ${
                        mediaOpen ? "media_drop_up_on" : ""
                      }`}
                    >
                      <ul>
                        <li>
                          <button
                            className={`media_select ask_que ${
                              activeTab === "math" ? "active" : ""
                            }`}
                            // onClick={() => handleAskQuestion()}
                          >
                            <img src={mathIcon} alt="why1" />
                            <p>Math</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que ${
                              activeTab === "gsIcon" ? "active" : ""
                            }`}
                          >
                            <img src={gkIcon} alt="gkIcon" />
                            <p>Genral Study</p>
                          </button>
                        </li>

                        <li>
                          <button
                            className={`media_select ask_que2 ${
                              activeTab === "link" ? "active" : ""
                            }`}
                            // onClick={() => handleLinkSearch()}
                          >
                            <img src={linkSearch} alt="linkSearch" />
                            <p>Link Search</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que3 ${
                              activeTab === "English" ? "active" : ""
                            }`}
                            // onClick={() => handlePsychologyTest()}
                          >
                            <img src={englishIcon} alt="englishIcon" />
                            <p>English</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que4 ${
                              activeTab === "ssb-gpt" ? "active" : ""
                            }`}
                            // onClick={() => handleImageGenrate("ssb-gpt")}
                          >
                            <img src={askSsbIcon} alt="ppdtIcon" />
                            <p>SSB Interview</p>
                          </button>
                        </li>

                        <li>
                          <button
                            className={`media_select ask_que6 ${
                              activeTab === "pdf" ? "active" : ""
                            }`}
                            onClick={() => handlePdfSearch()}
                          >
                            <img src={searchBook} alt="searchBook" />
                            <p>Book Search</p>
                          </button>
                        </li>

                        <li>
                          <button
                            className="media_select ask_que10"
                            onClick={() => handleNavigateCourses()}
                          >
                            <img src={courses} alt="wat1" />
                            <p>Courses</p>
                          </button>
                        </li>

                        <li>
                          <button
                            className={`media_select ask_que13 ${
                              activeTab === "currentAffair" ? "active" : ""
                            }`}
                            // onClick={() => handleSpokenEnglish()}
                          >
                            <img src={currentAffair} alt="currentAffair" />
                            <p>Current Affairs</p>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <button
                      className="media_btn"
                      onClick={() => handleMediaOpen()}
                    >
                      {mediaOpen ? <MdOutlineKeyboardArrowUp /> : <MdAdd />}
                    </button>
                  </div>

                  {searchValue === "" ? (
                    <button className="search" onClick={() => handleVoice()}>
                      {isListening ? <RiVoiceprintFill /> : <AiFillAudio />}
                    </button>
                  ) : (
                    <button className="search" onClick={() => handleSearch()}>
                      {isLoading ? <LuLoader /> : <CiSearch />}
                    </button>
                  )}
                </div>

                <p className="advice_txt">
                  CDS-GPT can make mistakes. We suggest seeking advice from
                  experts. Read our <Link to="/term-of-use">Terms</Link> and{" "}
                  <Link to="/privacy-policy">Privacy Policy</Link>.
                </p>
              </div>
            </div>
          </section>
          <GptBooks
            bookList={bookList}
            fetchBookList={fetchBookList}
            handleSave={saveValue}
            outputData={outputData}
            // activeTab={activeTab}
            questionList={questionList}
            handleShowHistory={handleShowHistory}
          />
        </section>

        <WhatCanDo />
      </GptLayout>
    </Fragment>
  );
};

export default GptSearch;
