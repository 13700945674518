import React, { useEffect } from "react";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./context/userContext";
import { Provider } from "react-redux";
import store from "./redux/store";
import { TourProvider, useTour } from "@reactour/tour";
import { createRoot } from "react-dom/client";

const steps = [
  {
    selector: ".tour_msg",
    content:
      "Take a Quick Tour—just 15 steps to help you get familiar with SSB-GPT.",
  },
  {
    selector: ".media_wrapper",
    content: "Get More tools to boost your preparation.",
  },
  {
    selector: ".ask_que",
    content: "Get answers to all the questions related to the SSB Interview.",
  },
  {
    selector: ".ask_que1",
    content: "Practice unlimited Officers Intelligence test.",
  },
  {
    selector: ".ask_que2",
    content:
      "Provide any link to get a summary or follow specific instructions.",
  },
  {
    selector: ".ask_que3",
    content:
      "Practice the Thematic Apperception Test with a timer and AI-generated images.",
  },
  {
    selector: ".ask_que4",
    content:
      "Practice the Picture Perception and Discussion Test with a timer and AI-generated images.",
  },
  {
    selector: ".ask_que5",
    content:
      "Practice the Situation Reaction Test with a timer and AI-generated situations.",
  },
  {
    selector: ".ask_que6",
    content:
      "Upload your book to train AI and engage in group study sessions with your AI Friend SSB-GPT.",
  },
  {
    selector: ".ask_que7",
    content: "Practice the Word Association Test with a timer.",
  },
  {
    selector: ".ask_que8",
    content: "Learn about the SSB from videos and add notes directly on them.",
  },
  {
    selector: ".ask_que9",
    content: "Practice GTO in 3D Simulation.",
  },
  {
    selector: ".ask_que10",
    content:
      "Enroll in top SSB courses taught by DIPR-trained retired officers.",
  },
  {
    selector: ".ask_que11",
    content: "Assess your Officer-Like Qualities (OLQs) with our MCQ test.",
  },
  {
    selector: ".ask_que12",
    content: "Practice unlimited mock interviews with our IO bot.",
  },
  {
    selector: ".book_tab",
    content:
      "Save your notes by subject or any way you prefer. Store chat-generated text, videos, images, and content from other sources.",
  },
];

const customStyles = {
  popover: (base) => ({
    ...base,
    backgroundColor: "#121D40",
    color: "white",
    borderRadius: "8px",
  }),
  dot: (base) => ({
    ...base,
    display: "none",
  }),
};

const MainComponent = () => {
  const { isOpen, setIsOpen } = useTour();

  useEffect(() => {
    // const visit = localStorage.getItem("visit");
    // if (!visit) {
    //   setIsOpen(true);
    //   localStorage.setItem("visit", 1);
    // } else {
    //   setIsOpen(false);
    // }
    setIsOpen(true);
  }, [setIsOpen]);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <UserProvider>
          <TourProvider
            steps={steps}
            styles={customStyles}
            prevButton={({ setIsOpen, currentStep }) => {
              if (currentStep === 0) {
                return (
                  <button
                    style={{
                      backgroundColor: "#fff",
                      border: "none",
                      color: "#121D40",
                      padding: "6px",
                      boxSizing: "border-box",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsOpen(false)}
                  >
                    No Thanks
                  </button>
                );
              } else {
                return null;
              }
            }}
            nextButton={({
              currentStep,
              stepsLength,
              setIsOpen,
              setCurrentStep,
              steps,
            }) => {
              const last = currentStep === stepsLength - 1;
              return (
                <button
                  style={{
                    backgroundColor: "#fff",
                    border: "none",
                    color: "#121D40",
                    padding: "6px",
                    boxSizing: "border-box",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (last) {
                      setIsOpen(false);
                    } else {
                      setCurrentStep((s) =>
                        s === steps?.length - 1 ? 0 : s + 1
                      );
                    }
                  }}
                >
                  {last ? "Done" : currentStep === 0 ? "Take Tour" : "Next"}
                </button>
              );
            }}
          >
            <App />
          </TourProvider>
        </UserProvider>
      </BrowserRouter>
    </Provider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<MainComponent />);
